import * as Sentry from "@sentry/browser";

export default class SentryErrorHandler {
    static init(dsn) {
        Sentry.init({
            dsn: dsn,
            integrations: [
                Sentry.captureConsoleIntegration({
                    levels: ['warn', 'error']
                }),
                Sentry.httpClientIntegration()
            ],
            ignoreErrors: [
                'Non-Error promise rejection captured'
            ],
            denyUrls: [
                /localhost/,

                // Google scripts
                /googletagmanager\.com/i,
                /google-analytics\.com/i,

                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,

                // Safari extensions
                /safari-extension:\/\//i,

                // Smartsupp
                /smartsuppchat\.com/i,
                /bootstrap\.smartsuppchat\.com/i,
                /v3\.smartsuppcdn\.com/i,
                /widget-v3\.smartsuppcdn\.com/i,

                // tawk.to
                /embed\.tawk\.to/i,
                /va\.tawk\.to/i,

                // Pinterest
                /ct\.pinterest\.com/i,
                /s\.pinimg\.com/i,

                // LinkedIn
                /px\.ads\.linkedin\.com/i
            ],
        });
    }
}
